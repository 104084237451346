import { SubmissionError, setSubmitSucceeded, reset, change, untouch } from 'redux-form'
import { getMappedApiErrorMessageKey, NETWORK_ERROR_KEY } from '../constants/utilities'

export function lookupSubmit(jackfruit, formValues) {
    return dispatch => {
        var myHeaders = new Headers({
            "Content-Type": "application/json",
            "X-Authorization-JWT": jackfruit.jwt
        });
        var myInit = {
            method: 'post',
            headers: myHeaders,
            body: JSON.stringify(formValues)
        };
        return fetch('/re-captcha/verify', myInit)
            .then(function (response) {
                return response.json();
            }).then(function (responseJson) {
                if (responseJson && responseJson.status ) {
                    return Promise.resolve(responseJson)
                } else if (responseJson) {
                    throw  getMappedApiErrorMessageKey(responseJson)
                } else {
                    throw NETWORK_ERROR_KEY;
                }
            }).then(()=> {
                return fetch('/rest/v1/users/purchase-lookup', myInit)
                    .then(function (response) {
                        return response.json();
                    }).then(function (responseJson) {
                        if (responseJson && responseJson.response) {
                            dispatch(reset('homeForm'));
                            dispatch(setSubmitSucceeded())
                        } else if(responseJson){
                            throw  getMappedApiErrorMessageKey(responseJson);
                        } else {
                            throw NETWORK_ERROR_KEY;
                        }
                })
            }).catch(function(err) {
                var errorString = _.isString(err) ? err : ((_.isObject(err) && err.message) ? err.message : NETWORK_ERROR_KEY);
                throw new SubmissionError({_error: errorString})
            });
    }
}
