let appConfig = {};

switch (APPLICATION_ENV) {
    case "local":
        appConfig = {
            'cdn' : 'http://webpack.revenuewire.ninja:8095/html'
        };
        break;
    case "sandbox":
    case "production":
        appConfig = {
            'cdn' : 'https://d1syp94efg8vx4.cloudfront.net/' + APP_VERSION
        };
        break;
    default:
        appConfig = {
            'cdn' : 'https://d1syp94efg8vx4.cloudfront.net/' + APP_VERSION
        };
        break;
}

export { appConfig };
