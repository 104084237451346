import { combineReducers } from 'redux';
import update from 'react-addons-update';
import { reducer as formReducer } from 'redux-form';
/// Reducers
function Home(state = {}, action)
{
    return state
}

const  HomeApp = combineReducers({
    home: Home,
    form: formReducer
});

export default HomeApp