import React from 'react';
const GradientBox = props => {
    if(props.minHeight){
        Object.assign(gradientBoxStyles,{"minHeight":props.minHeight})
    }
    return <div className="clearfix box" style={gradientBoxStyles}>{props.children}</div>;
}
const gradientBoxStyles = {
    "marginBottom" : "22px",
    "backgroundColor": "rgb(255, 255, 255)",
    "borderWidth": "1px",
    "borderStyle": "solid",
    "borderColor": "rgb(225, 225, 225)",
    "borderImage": "initial",
    "padding": "15px 25px",
    "borderRadius": "10px",
}
export default GradientBox
