const ROUNDING_LENGTH = 2;
const roundPrice = (price, roundingLength = ROUNDING_LENGTH)=>{return parseFloat(price).toFixed(roundingLength)}
export default roundPrice;

export const DEFAULT_ANCHOR_IMAGE_WIDTH = "40px";
export const BOX_SHOT_ANCHOR_IMAGE_WIDTH = "50px";
export const FORM_PUSH_CLASS = "col-md-8 offset-md-4";

export const TRUSTE_SEAL_URL = "//privacy.truste.com/privacy-seal/validation?rid=0e913ad0-dc4e-44c7-b921-88e9a8470714";
export const TRUSTE_ID = "0e913ad0-dc4e-44c7-b921-88e9a8470714";
export const TRUSTE_BADGE_URL = "//privacy-policy.truste.com/privacy-seal/seal?rid=0e913ad0-dc4e-44c7-b921-88e9a8470714";
export const NETWORK_ERROR_KEY = "network-error";
export const STATUS_CANCELED = "DEACTIVATED"
export const getMappedApiErrorMessageKey = (apiResponse)=>{
    if (apiResponse.key && initData.errorMapping[apiResponse.key]) {
        return initData.errorMapping[apiResponse.key]
    } else {
        return apiResponse.message ? apiResponse.message : 'default-api-error-message';
    }
}

export const getFirstStateValForCountry = (currentCountry, allCountries) => {
    const states = allCountries.find(country => country.id == (currentCountry)).states ;
    return  !_.isEmpty(states) ? states[0]['id'] : '';
}

const ccIdentificationPatterns = {
    electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
    maestro: /^(?!(?:5078))(?:5[0678]\d\d|6304|6390|67\d\d)\d{8,15}$/,
    dankort: /^(5019)\d+$/,
    interpayment: /^(636)\d+$/,
    unionpay: /^(62|88)\d+$/,
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5]\d{14}|222[1-9]\d{12}|22[3-9]\d{13}|2[3-6]\d{14}|27[0-1]\d{13}|2720\d{12}$/,
    amex: /^3[47][0-9]{13}$/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
    hipercard: /^(6062(11|82)\d{10}(\d{3})?)|(3841\d{15})$/,
    elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/,
    aura: /^(5078\d{2})(\d{2})(\d{8,11})$/,
};

export const ccValidator  = {
    is_visa: (cc) => ccIdentificationPatterns.visa.test(cc),

    // Is Mastercard
    is_mc: ( cc ) => ccIdentificationPatterns.mastercard.test(cc),

    // Is AMEX
    is_amex: ( cc ) => ccIdentificationPatterns.amex.test(cc),

    // Is Diners
    is_dine: ( cc ) => ccIdentificationPatterns.diners.test(cc),

    // Is Discover
    is_disc: ( cc ) => ccIdentificationPatterns.discover.test(cc),

    // Is JCB
    is_jcb: ( cc ) =>  ccIdentificationPatterns.jcb.test(cc) ,

    // Is Maestro
    is_maestro: ( cc ) => ccIdentificationPatterns.maestro.test(cc) ,

    // Hipercard
    is_hipercard: (cc) =>  ccIdentificationPatterns.hipercard.test(cc) ,

    // Elo
    is_elo: (cc)  => ccIdentificationPatterns.elo.test(cc) ,

    // Aura
    is_aura: (cc)  =>  ccIdentificationPatterns.aura.test(cc)
}

export function getCardType(cardNumber){
    if (ccValidator.is_visa(cardNumber)) {
        return 'visa';
    } else if (ccValidator.is_maestro(cardNumber)) {
        return 'maestro';
    } else if (ccValidator.is_mc(cardNumber)) {
        return 'mastercard';
    } else if (ccValidator.is_amex(cardNumber)) {
        return 'amex';
    } else if (ccValidator.is_disc(cardNumber)) {
        return 'discover';
    } else if (ccValidator.is_jcb(cardNumber)) {
        return 'jcb';
    } else if (ccValidator.is_dine(cardNumber)) {
        return 'diners';
    } else if (ccValidator.is_aura(cardNumber)) {
        return 'aura';
    } else if (ccValidator.is_elo(cardNumber)) {
        return 'elo';
    } else if (ccValidator.is_hipercard(cardNumber)) {
        return 'hipercard';
    }
    return undefined;
}
