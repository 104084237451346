import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

class  ReCaptcha  extends React.Component {

    componentWillUpdate(nextProps, nextState){
        if(nextProps.reset != undefined && nextProps.reset != this.props.reset ){
            this.reCaptcha.reset();
        }
    }
    render() {
        return <div>
            <ReCAPTCHA
                sitekey={this.props.recaptchaSiteKey}
                onChange={response => this.props.input.onChange(response)}
                ref={(input) => { this.reCaptcha = input; }}
            />
            {this.props.meta.touched && (this.props.meta.error && <span className="text-danger"><FormattedMessage id={this.props.meta.error} /></span>)}
        </div>
    }
}

ReCaptcha.propTypes = {
    input: PropTypes.object.isRequired
};

export default ReCaptcha;
