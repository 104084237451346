import "babel-polyfill";

import {appConfig} from "./config";
import React from 'react'
import ReactDOM from 'react-dom';
import {IntlProvider, addLocaleData } from 'react-intl';
import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'

function render(App, reducer) {
    if (lang != 'en') {
        addLocaleData(ReactIntlLocaleData[lang]);
    }
    let messageFile = "global";

    let store = createStore(reducer, composeWithDevTools(applyMiddleware(thunkMiddleware)));
    const targetEl = document.getElementById('app-div');

    if (lang === 'en') {
        /**
         * Pre-build the english file into the app. This allow us to have faster performance of the default languages.
         */
        let messages = require("./../../html/messages/" + messageFile + ".en.json");
        ReactDOM.render(
            <Provider store={store}>
                <IntlProvider locale={lang} messages={messages}>
                    <App initData={initData}/>
                </IntlProvider>
            </Provider>,
            targetEl
        );
    } else {
        $.getJSON(appConfig.cdn + '/messages/' + messageFile + "." + lang + ".json", function (messages) {
            ReactDOM.render(
                <Provider store={store}>
                    <IntlProvider locale={lang} messages={messages}>
                        <App initData={initData}/>
                    </IntlProvider>
                </Provider>,
                targetEl
            );
        });
    }
}

export default render;
