import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const StandardField = ({ input, placeholder, containerClasses, inputClasses, type, meta: { touched, error, warning }, errorMessageValues }) => {
    return <span className={(containerClasses || "col-5") + ((touched && error) ? " has-error" : "")}>
        <input {...input} placeholder={placeholder} type={type} className={inputClasses || "form-control"} autoComplete={type === 'password' ? "off" : false }/>
        {touched && (error &&
            <span className="text-danger"><FormattedMessage id={error} values={errorMessageValues}/></span>
            )
        }
    </span>
}

export default StandardField