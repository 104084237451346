import {getCardType} from './utilities'
const formFieldValidator = {
    'required': value => value && value.trim() ? undefined : 'required',
    'allowedCard': allowedCards => cardNumebr => allowedCards.includes(getCardType(cardNumebr))? undefined : 'unsupported-card',
    'number': value => value && isNaN(Number(value)) ? 'must-be-a-number' : undefined,
    'minLength': min => value => value && value.length && value.length < min ? 'must-be-min-length' : undefined,
    'maxLength': max => value => value && value.length && value.length > max ? 'must-be-max-length' : undefined,
    'length': length => value => value && value.length && value.length !== length ? 'must-be-length' : undefined,
    'email': value =>
        value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
            'invalid-email' : undefined,
    'expiry': value => {
        if (value.length == 5) {
            var re = /(..)\/(..)/;
            var month = parseInt(value.match(re)[1]);
            var year = parseInt(value.match(re)[2]);
            var curYear = parseInt(new Date().getFullYear().toString().substr(2, 2));
            var curMonth = new Date().getMonth() + 1; // because getMonth returns 0-11
            var monthDiff = (month + 12 * year) - (curMonth + 12 * curYear);
            // valid month and expiry year has to be greater then then current by no more then 15 years
            return (monthDiff >= 0 && monthDiff < 15 * 12 && month >= 0 && month < 13) ? undefined : "incorrect-expiry-date"
        }
        return 'incorrect-expiry-date'
    },
    'compare': (value, compareVal) => {return (value === undefined || value === compareVal) ? undefined : 'has-to-be-same-as-password'},
    'compareNotEqual': (value, compareVal) => { return (value === undefined || value !== compareVal) ? undefined : 'has-to-be-different'},
    'password' : value => value && value.length < 8 ? "password-length" : undefined
}
 export default formFieldValidator;