import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

const ServerAlert =  (props) => {
    let iconClasses = 'glyphicon icon-';
    if (props.alertType === 'success') {
        iconClasses += 'ok-sign text-success';
    } else if (props.alertType === 'info') {
        iconClasses += 'info-sign text-info';
    } else if (props.alertType === 'warning') {
        iconClasses += 'exclamation-sign text-warning';
    } else if (props.alertType === 'danger') {
        iconClasses += 'exclamation-sign text-danger';
    }

    let showContactUs = false;
    if (["default-api-error-message", "network-error", "verify-fail", "system-error"].indexOf(props.message) >= 0) showContactUs = true;

    return <div className={"toast-alert media " + props.alertType}>
        <div className={"align-self-center " + iconClasses} >
        </div>
        <div className="media-body align-self-center">
            <h5><FormattedMessage id={props.message} values={props.values}/></h5>
            {showContactUs &&
                <span><FormattedMessage id="if-problem-continues" /> <a href="/support/contact-us"><FormattedMessage id="contact-us" /></a></span>}
        </div>
        {props.closeable &&
        <div className="align-self-center">
            <button type="button"
                    className="close"
                    onClick={e=>{e.preventDefault(); props.onAlertDismiss()}}>
                <span>&times;</span>
            </button>
        </div>
        }
    </div>
}

ServerAlert.defaultProps = {
    alertType: 'danger',
    onAlertDismiss: ()=>{},
    closeable: true,
    values:{}
};

ServerAlert.propTypes = {
    values: PropTypes.object,
    message: PropTypes.string,
    onAlertDismiss: PropTypes.func.isRequired,
    alertType: PropTypes.oneOf(['success', 'info', 'warning', 'danger']).isRequired
};
export default ServerAlert;
