import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const SectionHeading = (props) => {
        return  <h3 style={{color: props.domainSettings.sectionHeaderColor}}>
                    <FormattedMessage id={props.id} values={{companyName:props.domainSettings.name}}/>
                </h3>
}

export default SectionHeading
