import React from 'react';
import 'lodash'
import { FormattedMessage, injectIntl } from 'react-intl';
import TopNavItem from './TopNavItem.jsx';

const Header = (props) =>{

    let baseHeaderText = props.domainSettings.custom ? "header-text-generic" : "header-text-home";

    let bannerStyle = {
        borderBottomColor: props.domainSettings.headerAccentColor,
        backgroundImage: "linear-gradient(to bottom, " + props.domainSettings.headerBannerColor + ", " + props.domainSettings.headerBannerColorBottom + ")"
    };

    let navItems = [{"href" : "/", "messageId" : "sc-home"}];

    if (!_.isEmpty(props.user)) {
        navItems.push(
        {"href" : "/users/logout", "messageId" : "logout"},
        {"href" : "/my-account", "messageId" : "my-account"}
        );
    } else {
        navItems.push({"href" : "/users/login", "messageId" : "login"});
    }

    navItems.push(
        {"href" : "/support/contact-us", "messageId" : "contact-us"}
    );


    return <div id="header">
        <div className="header-full-width clearfix" id="header-logo-nav" style={{borderTopColor: props.domainSettings.headerAccentColor}}>
            <div className="container header-wrapper" >
                <div id="header-logo" className="pull-left">
                    <a href="/" ><img src={props.domainSettings.logoUrl} alt={props.domainSettings.name ? props.domainSettings.name : "SafeCart®"} /></a>
                </div>
                <div className="pull-right">
                    <div id="nav-runner" style={{backgroundColor: props.domainSettings.headerAccentColor}}> </div>
                    <div id="nav-container">
                    <ul id="top-nav" >
                        {navItems.map( (item) =>
                            <TopNavItem key={item.messageId} messageId={item.messageId} href={item.href} domainSettings={props.domainSettings} />)}
                    </ul>
                    </div>
                </div>
            </div>
        </div>

        <div className="header-full-width" id="header-banner" style={bannerStyle}>
            <div className="container header-wrapper" id="inner-header-banner" >
                <h2 className={props.largeText ? "large-header-text" : ""}>
                    <FormattedMessage id={props.headerText ? props.headerText : baseHeaderText} values={{"companyName":props.domainSettings.name}} />
                </h2>
            </div>
        </div>
    </div>
}

export default Header;
