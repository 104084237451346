/**
 * Library
 */
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm, stopAsyncValidation, formValueSelector, initialize } from 'redux-form';
import formFieldValidator from './../../constants/formFieldValidator';
import 'fetch-ie8';

/**
 * Containers
 */

import Header from '../../components/Header.jsx'
import Footer from '../../components/Footer.jsx'
import GradientBox from '../../components/GradientBox.jsx'
import ServerAlert from '../../components/ServerAlert.jsx'
import ReCaptcha from '../../components/ReCaptcha.jsx'
import StandardField from '../../components/StandardInput.jsx'
import SectionHeading from '../../components/SectionHeading.jsx'
import {FORM_PUSH_CLASS} from '../../constants/utilities'

/**
 * Require Default CSS
 */

require('../../scss/support-combined.scss');

import {submit, lookupSubmit} from '../actions.js'

const mapStateToProps = (state) => {return state;}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onLookup: (formValues) => {
            return dispatch(lookupSubmit(ownProps.initData.jackfruit, formValues));
        },
        onAlertDismiss: () => {
            dispatch(stopAsyncValidation('homeForm'))
            dispatch(initialize('homeForm', { keepSubmitSucceeded: false }))
        }
    }
};

class Home extends React.Component {
    render() {
        return <div id="outer-wrapper" >
            <Header largeText={true} user={this.props.initData.user} domainSettings={this.props.initData.domainSettings} />
            <div className="container inner-wrapper" >
                <div className={"clearfix col-sm-8 " + (this.props.initData.domainSettings.custom ? "center-block" : "")} >
                    <div className="" >
                        <GradientBox minHeight="350px">
                            <HomeForm onLookup={this.props.onLookup}
                                      formatMessage={this.props.intl.formatMessage}
                                      onAlertDismiss={this.props.onAlertDismiss}
                                      domainSettings={this.props.initData.domainSettings}
                            />
                        </GradientBox>
                    </div>
                </div>
            </div>
            <Footer domainSettings={this.props.initData.domainSettings} />

        </div>
    }
}

let HomeForm = props => {
    const { error, handleSubmit, submitting, onLookup, submitSucceeded, email } = props
    return <form onSubmit={handleSubmit(onLookup)} className="form-horizontal">
       {error && <ServerAlert message={error} onAlertDismiss={props.onAlertDismiss} values={{"newEmail":email}}/>}
       {submitSucceeded && <ServerAlert message={'lookup-success-msg'} onAlertDismiss={props.onAlertDismiss} alertType="success" />}

       {!props.domainSettings.custom && <img src={imageBaseURL + "lock-copy.png"} alt="100% Secure" className="secure-badge" />}

       <SectionHeading domainSettings={props.domainSettings} id="lookup-your-purchase"/>
       <p className="margin-bottom"><FormattedMessage id="lookup-your-purchase-instructions" values={{"companyName":props.domainSettings.name}}/></p>
       <div className="form-group row">
            <label className="col-form-label col-4 text-right" for="email">
               <FormattedMessage id="your-email"/>
            </label>
            <Field component={StandardField}
                   containerClasses="col-8"
                   type="email"
                   name="email"
                   validate={[formFieldValidator.required, formFieldValidator.email]}
            />
        </div>
       <div className="form-group row">
           <div className={FORM_PUSH_CLASS}>
               <a href="/users/order-login" ><FormattedMessage id="account-login"/></a>
           </div>
       </div>
       <div className="form-group row">
           <div className={FORM_PUSH_CLASS}>
               <Field name='captchaResponse'
                      component={ReCaptcha}
                      validate={[formFieldValidator.required]}
                      props={{"reset":(error || submitSucceeded), "recaptchaSiteKey":props.domainSettings.recaptchaSiteKey}}
               />
           </div>
       </div>
        <div className="form-group row">
            <div className={FORM_PUSH_CLASS}>
                <button type="submit" disabled={submitting}  className={"spo-btn-submit "+(submitting ? "submitting" : "")} >
                    <FormattedMessage id="lookup-purchase"/>
                </button>
            </div>
        </div>
    </form>
}
HomeForm = reduxForm({form: 'homeForm'})(HomeForm);
const selector = formValueSelector('homeForm');
HomeForm = connect(
    state => {
        const email = selector(state, 'email')
        return { email }
    }
)(HomeForm)

const HomeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(Home));


export default HomeContainer;
