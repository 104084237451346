import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const Footer =  (props) => {
    return <div  id="footer" style={{paddingTop: "1rem"}} >
        {!props.domainSettings.custom &&
        <div className="container">
            <div className="col-sm-12 ">
                <ul className="clearfix" id="footer-nav">
                    <li><a href="/"><FormattedMessage id="sc-home"/></a></li>
                    {(props.user === undefined) && <li><a href="/"><FormattedMessage id="purchase-lookup"/></a></li>}
                    <li><a href={props.domainSettings.privacyPolicy ? props.domainSettings.privacyPolicy : "/privacy-policy"}><FormattedMessage id="privacy-policy"/></a></li>
                    <li><a href={props.domainSettings.termsOfSale ? props.domainSettings.termsOfSale : "/terms-of-sale"}><FormattedMessage id="terms-of-sale"/></a></li>
                    <li><a href={props.domainSettings.termsOfUse ? props.domainSettings.termsOfUse : "/terms-of-use"}><FormattedMessage id="terms-of-use"/></a></li>
                    <li className="last"><FormattedMessage id="all-rights" values={{companyName:props.domainSettings.name}} /></li>
                </ul>
            </div>
        </div>
        }
        {props.domainSettings.custom &&
        <div className="container">
            <div className="col-sm-12 ">
                <ul className="clearfix" id="footer-nav">
                    <li><a href="/"><FormattedMessage id="sc-home"/></a></li>
                    {(props.user === undefined) && <li><a href="/"><FormattedMessage id="purchase-lookup"/></a></li>}
                    <li><a href={props.domainSettings.privacyPolicy ? props.domainSettings.privacyPolicy : "/privacy-policy"}><FormattedMessage id="privacy-policy"/></a></li>
                    <li><a href={props.domainSettings.termsOfSale ? props.domainSettings.termsOfSale : "/terms-of-sale"}><FormattedMessage id="terms-of-sale"/></a></li>
                    <li className="last"><a href={props.domainSettings.termsOfUse ? props.domainSettings.termsOfUse : "/terms-of-use"}><FormattedMessage id="terms-of-use"/></a></li>
                </ul>
            </div>
        </div>
        }
    </div>
}

export default Footer
